<template>
  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-end mb-0">
        <li class="dropdown notification-list topbar-dropdown">
          <a
            class="
              nav-link
              dropdown-toggle
              nav-user
              me-0
              waves-effect waves-light
            "
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <img
              :src="user.profilePicture.thumbnail"
              alt="user-image"
              class="rounded-circle"
            />
            <span class="pro-user-name ms-1">
              {{ user.firstName }} {{ user.lastName }}
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end profile-dropdown">
            <!-- item-->
            <router-link
              :to="{ name: 'Logout' }"
              class="dropdown-item notify-item"
            >
              <i class="fe-log-out"></i>
              <span>Logout</span>
            </router-link>
          </div>
        </li>
      </ul>

      <!-- LOGO -->
      <div class="logo-box text-white">
        <a href="" class="logo text-center text-white">
          <span class="logo-lg text-white">
            <img
              class="logo-image"
              :src="SITE_URL + '/img/logo.92f541ab.png'"
              alt=""
              height="50"
            />
            <b class="ml-2"> Eparchemin</b>
            <!-- <span class="logo-lg-text-light">U</span> -->
          </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>
          <button class="button-menu-mobile waves-effect waves-light">
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <!-- Mobile menu toggle (Horizontal Layout)-->
          <a
            class="navbar-toggle nav-link"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
// import { APIUrl } from "../../http";
import { SITE_URL } from "../../http/index.js";
import jwtDecode from "jwt-decode";
import { useRouter } from "vue-router";
import { onBeforeMount } from "@vue/runtime-core";
import { ref } from "vue";
import { useRoute } from "vue-router";

const store = useStore();
const init = {
  profilePicture: { thumbnail: "" },
  firstName: "",
  lastName: "",
};
const refresh = localStorage.getItem("refresh") || null;
const user = ref(init);
const route = useRoute();

onBeforeMount(() => {
  if (
    refresh &&
    refresh.length > 30 &&
    jwtDecode(refresh).exp * 1000 > new Date().getTime()
  ) {
    if (route.name != "CheckAuth")
      store
        .dispatch("refreshToken")
        .then(() => {
          store.dispatch("loadUser").then(() => {
            const u = localStorage.getItem("u") || JSON.stringify(init);
            console.log(u);
            user.value = JSON.parse(u);
          });
        })
        .catch(() => {
          useRouter().push({ name: "Logout" });
        });
  }
});
</script>
<style>
.logo-image {
  border-radius: 50%;
}
</style>

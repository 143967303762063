import jwtDecode from "jwt-decode";
import { createStore } from "vuex";
import { getAPI } from "../http";
import router from "../router";

export default createStore({
  state: {
    refreshTokenTimer: null,
    access: localStorage.getItem("access") || null,
    teachers: { updated: false, data: null },
    allUsers: { updated: false, data: null },
    students: { updated: false, data: null },
    courses: { updated: false, data: null },
    categories: { updated: false, data: null },
    codes: { updated: false, data: null },
    enrolments: { updated: false, data: null },
    payments: { updated: false, data: null },
    earnings: { updated: false, data: null },
    donations: { updated: false, data: null },
    user: { updated: false, data: null },
  },
  getters: {
    loggedIn() {
      const access = localStorage.getItem("access") || null;
      return access != null;
    },
  },
  mutations: {
    updateAccess(state, access) {
      state.access = access;
      localStorage.setItem("access", access);
    },
    destroyToken(state) {
      localStorage.clear();
      state.access = null;
    },
    updateUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    loginCheck(context) {
      console.log(context);
    },
    async refreshToken(context) {
      await getAPI
        .post("/auth/token/refresh/", {
          refresh: localStorage.getItem("refresh"),
        })
        .then((response) => {
          context.commit("updateAccess", response.data.access);
          context.dispatch("loadUser").then(() => {
            context.dispatch("startRefreshTokenTimer");
          });
        })
        .catch(() => {
          router.push({ name: "Logout" });
        });
    },

    logout(context) {
      return new Promise((resolve) => {
        localStorage.clear();
        context.commit("destroyToken");
        context.dispatch("stopRefreshTokenTimer");
        resolve();
      });
    },
    startRefreshTokenTimer(context) {
      if (context.state.refreshTokenTimer != null)
        context.dispatch("stopRefreshTokenTimer");
      const jwtToken = jwtDecode(localStorage.getItem("access"));
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      context.state.refreshTokenTimer = setTimeout(
        () => context.dispatch("refreshToken"),
        timeout
      );
    },
    stopRefreshTokenTimer(context) {
      clearTimeout(context.state.refreshTokenTimer);
      context.state.refreshTokenTimer = null;
    },
    async loadTeachers(context) {
      if (context.state.teachers === null || !context.state.teachers.updated) {
        await getAPI.get(`/auth/admin/teacher-list`).then((response) => {
          context.state.teachers = { updated: true, data: response.data };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.teachers.data),
        (reject) => reject()
      );
    },
    async loadCourses(context) {
      if (context.state.courses === null || !context.state.courses.updated) {
        await getAPI.get(`/courses/list/`).then((response) => {
          context.state.courses = {
            updated: true,
            data: response.data,
          };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.courses.data),
        (reject) => reject()
      );
    },
    async loadCategories(context) {
      if (
        context.state.categories === null ||
        !context.state.categories.updated
      ) {
        await getAPI.get(`/courses/category/all/`).then((response) => {
          context.state.categories = {
            updated: true,
            data: response.data,
          };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.categories.data),
        (reject) => reject()
      );
    },
    async loadCodes(context) {
      if (context.state.codes === null || !context.state.codes.updated) {
        await getAPI.get(`/promocode/list-create`).then((response) => {
          context.state.codes = {
            updated: true,
            data: response.data,
          };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.codes.data),
        (reject) => reject()
      );
    },
    async loadEnrolments(context) {
      if (!context.state.enrolments.updated) {
        await getAPI.get(`/enrolment/enrolment-list`).then((response) => {
          context.state.enrolments = {
            updated: true,
            data: response.data,
          };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.enrolments.data),
        (reject) => reject()
      );
    },
    async loadEarnings(context) {
      if (!context.state.earnings.updated) {
        await getAPI
          .get(`/auth/admin/list-all-teacher-gain`)
          .then((response) => {
            context.state.earnings = {
              updated: true,
              data: response.data,
            };
          });
      }
      return new Promise(
        (resolve) => resolve(context.state.earnings.data),
        (reject) => reject()
      );
    },
    async loadUser(context) {
      if (!context.state.user.updated) {
        let conf = {};
        const access = localStorage.getItem("access") || null;
        if (access != null)
          conf = { headers: { Authorization: `Bearer ${access}` } };
        await getAPI
          .get("/auth/user/infos", conf)
          .then((response) => {
            if (response.data.groups != "admin")
              router.push({ name: "Logout" });
            context.state.user = {
              updated: true,
              data: response.data,
            };
            const { profilePicture, firstName, lastName } = response.data;
            const u = {
              profilePicture: { thumbnail: profilePicture.thumbnail },
              firstName,
              lastName,
            };
            localStorage.setItem("u", JSON.stringify(u));
          })
          .catch(() => router.push({ name: "Logout" }));
      }
      return new Promise(
        (resolve) => resolve(context.state.user.data),
        (reject) => reject()
      );
    },
    async loadPayments(context) {
      if (!context.state.payments.updated) {
        await getAPI
          .get(`/auth/admin/payment-request-list`)
          .then((response) => {
            context.state.payments = {
              updated: true,
              data: response.data,
            };
          });
      }
      return new Promise(
        (resolve) => resolve(context.state.payments.data),
        (reject) => reject()
      );
    },
    async loadDonations(context) {
      if (!context.state.donations.updated) {
        await getAPI.get(`/payment/admin-donation-list`).then((response) => {
          context.state.donations = {
            updated: true,
            data: response.data,
          };
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.donations.data),
        (reject) => reject()
      );
    },
    async loadStudents(context, flag) {
      if (!context.state.allUsers.updated) {
        await getAPI.get(`/auth/admin/user-list`).then((response) => {
          context.state.students = {
            updated: true,
            data: response.data.filter((u) => u.groups === "student"),
          };
          context.state.allUsers = { updated: true, data: response.data };
        });
      }
      return new Promise(
        (resolve) =>
          resolve(
            flag === "all"
              ? context.state.allUsers.data
              : context.state.students.data
          ),
        (reject) => reject()
      );
    },
  },
  modules: {},
});

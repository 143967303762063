<template>
  <div class="left-side-menu">
    <div class="h-100" data-simplebar>
      <!-- User box -->
      <div class="user-box text-center">
        <img
          src="https://coderthemes.com/ubold/layouts/assets/images/users/user-1.jpg"
          alt="user-img"
          title="Mat Helme"
          class="rounded-circle avatar-md"
        />
      </div>

      <!--- Sidemenu -->
      <div id="sidebar-menu" v-if="loggedIn">
        <ul id="side-menu">
          <li>
            <a href="#sidebarUsers" data-bs-toggle="collapse">
              <i class="fas fa-users"></i>
              <span> Utilisateurs </span>
              <span class="fas fa-chevron-right menu-arrow"></span>
            </a>
            <div class="collapse" id="sidebarUsers">
              <ul class="nav-second-level">
                <li>
                  <router-link :to="{ name: 'Teachers' }"
                    >Enseignants</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Students' }"
                    >Etudiants</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Users' }">Tous</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#sidebarCourses" data-bs-toggle="collapse">
              <i class="fas fa-book"></i>
              <span> Cours et categories </span>
              <span class="fas fa-chevron-right menu-arrow"></span>
            </a>
            <div class="collapse" id="sidebarCourses">
              <ul class="nav-second-level">
                <li>
                  <router-link :to="{ name: 'Courses' }">cours</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Categories' }"
                    >categories</router-link
                  >
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#sidebarEnrolment" data-bs-toggle="collapse">
              <i class="fas fa-sticky-note"></i>
              <span>Inscriptions ...</span>
              <span class="fas fa-chevron-right menu-arrow"></span>
            </a>
            <div class="collapse" id="sidebarEnrolment">
              <ul class="nav-second-level">
                <li>
                  <router-link :to="{ name: 'Enrolments' }"
                    >Inscriptions</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Codes' }"
                    >Codes promos</router-link
                  >
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a href="#sidebarRequests" data-bs-toggle="collapse">
              <i class="fas fa-tasks"></i>
              <span>Gains et requettes</span>
              <span class="fas fa-chevron-right menu-arrow"></span>
            </a>
            <div class="collapse" id="sidebarRequests">
              <ul class="nav-second-level">
                <li>
                  <router-link :to="{ name: 'Earnings' }">Gains</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Payments' }"
                    >Requettes de paiements</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Donations' }">Dons</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- End Sidebar -->

      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>
<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.state.access != null;
    },
  },
};
</script>
<style lang=""></style>

import axios from "axios";
import store from "../store";
import jwtDecode from "jwt-decode";
const APIUrl = "https://api.eparchemin.com";
const SITE_URL = "https://eparchemin.com";

const _axios = axios.create({
  baseURL: APIUrl,
  headers: {
    contentType: "application/json",
  },
});
_axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const getAPI = axios.create({
  baseURL: APIUrl,
});
let access = localStorage.getItem("access");
let refresh = localStorage.getItem("refresh");
if (access != null && refresh != null) {
  if (jwtDecode(access).exp * 1000 > new Date().getTime()) {
    getAPI.defaults.headers.common["Authorization"] = `Bearer ${access}`;
  } else if (jwtDecode(refresh).exp * 1000 < new Date().getTime())
    store.dispatch("logout");
}

getAPI.interceptors.request.use(function (req) {
  let access = localStorage.getItem("access");
  if (access != null && jwtDecode(access).exp * 1000 > new Date().getTime())
    req.headers.common["Authorization"] = `Bearer ${access}`;
  return Promise.resolve(req);
});

getAPI.interceptors.response.use(undefined, function (err) {
  if (err.config && err.response && err.response.status === 401) {
    access = localStorage.getItem("access");
    if (access == null || jwtDecode(access).exp * 1000 < new Date().getTime()) {
      store.dispatch("logout");
    }
  }
  return Promise.reject(err);
});

export { getAPI, APIUrl, SITE_URL };

import { createApp } from "vue";
import VueAxios from "vue-axios";
import App from "./App.vue";
import { getAPI } from "./http";
import router from "./router";
import store from "./store";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Toaster from "@meforma/vue-toaster";





createApp(App)
  .use(store)
  .use(VueAxios, getAPI)
  .use(Toaster)
  .use(router)
  .mount("#app");


  Sentry.init({
    App,
    dsn: "https://5c4e9479a80f4bde82c3b7119291bb2a@o1048445.ingest.sentry.io/6037414",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "https://admin-eparchemin.herokuapp.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  
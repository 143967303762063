<template lang="">
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">&copy; {{ currentYear }} eparchemin team</div>
        <div class="col-md-6">
          <div class="text-md-end footer-links d-none d-sm-block">
            <a href="javascript:void(0);">About Us</a>
            <a href="javascript:void(0);">Help</a>
            <a href="javascript:void(0);">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style lang=""></style>

import jwtDecode from "jwt-decode";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/auth/:refresh",
    name: "CheckAuth",
    component: () => import("../views/auth/CheckAuth.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/auth/Logout.vue"),
  },
  {
    path: "/users/all",
    name: "Users",
    component: () => import("../views/users/Users.vue"),
  },
  {
    path: "/users/teachers",
    name: "Teachers",
    component: () => import("../views/users/Teachers.vue"),
  },
  {
    path: "/users/students",
    name: "Students",
    component: () => import("../views/users/Students.vue"),
  },
  {
    path: "/users/:id",
    name: "ShowUser",
    component: () => import("../views/users/EditUser.vue"),
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/teaching/Courses.vue"),
  },
  {
    path: "/courses/:id",
    name: "ShowCourse",
    component: () => import("../views/teaching/EditCourse.vue"),
  },
  {
    path: "/courses/teacher-:id",
    name: "TeacherCourses",
    component: () => import("../views/teaching/TeacherCourses.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/teaching/Categories.vue"),
  },
  {
    path: "/enrolments",
    name: "Enrolments",
    component: () => import("../views/enrolment/Enrolments.vue"),
  },
  {
    path: "/codes",
    name: "Codes",
    component: () => import("../views/enrolment/Codes.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    component: () => import("../views/requests/Payments.vue"),
  },
  {
    path: "/earning",
    name: "Earnings",
    component: () => import("../views/requests/Earnings.vue"),
  },
  {
    path: "/donations",
    name: "Donations",
    component: () => import("../views/requests/Donations.vue"),
  },
  {
    path: "/donations/:id",
    name: "ShowDonation",
    component: () => import("../views/requests/ShowDonation.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export const notAutRoutes = ["CheckAuth", "Home"];
router.beforeEach((to, from, next) => {
  const access = localStorage.getItem("access");
  const isAuthenticated =
    access != null && jwtDecode(access).exp * 1000 > new Date().getTime();

  if (notAutRoutes.indexOf(to.name) == -1 && !isAuthenticated) {
    next({ name: "Home" });
  } else if (to.name === "CheckAuth" && isAuthenticated)
    next({ name: "Teachers" });
  next();
});
export default router;
